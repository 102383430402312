@import '../../../assets/styles/partials/variables';

.wrapper {
  display: flex;
  align-items: center;
}

.wrapperInline {
  display: inline-flex;
}

.button {
  flex: 1;
  border-radius: 0;
  margin-left: -1px;
  padding: 8px 23px !important;
  z-index: 0;
  &:active {
    background-color: #ffffff !important;
    border: 1px solid $main-text-color !important;
  }
  &:hover {
    background-color: #ffffff !important;
    z-index: 10;
  }
}

.buttonInline {
  font-weight: normal;
  white-space: nowrap;
}

.buttonShrink {
  padding: 8px !important;
}

.buttonActive {
  border: 1px solid $color-malachite-02;
  &:active {
    background-color: $color-malachite-02 !important;
  }
  &:hover {
    background-color: $color-malachite-02 !important;
  }
  &:focus {
    border: 1px solid $outline-focus-color !important;
  }
  &:disabled {
    border: 1px solid $color-malachite-04 !important;
  }
}

.buttonFirst {
  margin-left: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.buttonLast {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
