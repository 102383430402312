@import '../../../assets/styles/partials/variables';

.languageSelector {
  margin-bottom: 5px;
}

.error {
  color: $error-color !important;
}

.warning {
  color: $outline-focus-color !important;
}
