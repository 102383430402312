@import '../../../assets/styles/partials/variables';

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
  color: $link-color;
  outline: none;
  &:hover {
    color: $color-malachite-02;
  }
  &:disabled {
    color: $secondary-text-color;
    cursor: default;
  }
}
