@import '../../../assets/styles/partials/variables';

.button {
  display: inline-flex; // нужен inline-flex чтобы высота кнопки была равна высоте иконки
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: $link-color;
  outline: none;
}
