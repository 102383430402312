$size: 20px;

.container {
  position: relative;
}

.spinnerWrapper {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  + input {
    padding-right: 40px !important;
  }
}
