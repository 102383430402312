@import '../../../assets/styles/partials/variables';

$radius: 16px;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $overlay-background-color;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  overflow: auto;
}

.content {
  position: relative;
  margin: 0 auto;
  animation: fadeIn 300ms;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  outline: none;
}

.contentSM {
  max-width: 480px;
}

.contentMD {
  max-width: 600px;
}

.contentLG {
  max-width: 800px;
}

.contentXL {
  max-width: 1000px;
}

.closeButton {
  display: none;
  position: fixed;
}

.close {
  color: $color-asphalt-03;
}

.header {
  padding: 12px $tablePaddingHorizontalXS;
  font-size: 16px;
  font-weight: 600;
  background-color: #ffffff;
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

.headerColor {
  background-color: $secondary-bg-color;
}

.body {
  padding: 12px $tablePaddingHorizontalXS;
  background-color: #ffffff;
  overflow: auto;
}

.bodyMinHeight {
  min-height: 100px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 12px $tablePaddingHorizontalXS;
  background-color: #ffffff;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  & > * {
    margin-left: 0 !important;
    margin-right: 16px !important;
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.footerColor {
  background-color: $secondary-bg-color;
}

.noPadding {
  padding: 0;
  background-color: transparent;
}

@media (min-width: $breakpointSM) {
  .header {
    font-size: 21px;
  }

  .overlay {
    padding: 12px 40px;
  }

  .header {
    padding: 24px $tablePaddingHorizontal;
  }

  .body {
    padding: 16px $tablePaddingHorizontal;
  }

  .footer {
    padding: 24px $tablePaddingHorizontal;
  }

  .closeButton {
    display: block;
  }

  .noPadding {
    padding: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
