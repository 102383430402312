@import '../../../assets/styles/partials/variables';

.password {
  position: relative;
}
.eye{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-asphalt-02;
  &:focus {
    outline: none;
  }
}
