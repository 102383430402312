@import '../../../assets/styles/partials/variables';

.container {}

.containerMarginBottom {
  margin-bottom: 16px;
}

.control {}

.error {
  color: $error-color;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  svg {
    margin-right: 4px;
  }
}
.error_border {
  border: 1px solid $error-color;
}

.title {
  margin-bottom: 4px;
}

.titleCenter {
  display: flex;
  align-items: center;
  height: 100%;
}

.helperText {
  color: $secondary-text-color;
}

.helperTextMargin {
  margin-top: 4px;
}

@media (min-width: $breakpointSM) {
  .title {
    margin-bottom: 0;
  }
}
