@import './partials/variables';
@import '../../components/vendor/@sbbol/icons/css/icons.css';

.sort-fill { // for the table's svg icons
  fill: #7D838A;
}
.paginator-secondary-fill {
  fill: #F2F4F7;
}
.paginator-primary-fill {
  fill: #565B62;
}
.hoverable:disabled .paginator-primary-fill {
  fill: #D0D7DD;
}

.text-bold {
  font-weight: bold;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
