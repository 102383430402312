@import '@stylesConst/variables';

.container {
  display: flex;
}

.bold {
  font-weight: bold;
  margin-right: 5px;
}

.grayText {
  color: $color-asphalt-01;
}
