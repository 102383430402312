@import '../../../assets/styles/partials/variables';

.notification{
  position: fixed;
  right: 10px;
  z-index: 9999;
}
.wrap {
  .title {
    margin-bottom: 7px;
  }
  .value {
    padding: 3px 0;
  }
}
