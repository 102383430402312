@import './partials/variables';
@import './partials/overrides';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: SBSansUI, Roboto, Helvetica Neue, Arial, sans-serif;
  width: 100%;
  min-height: 100%; // для виджета загрузки
  font-size: 14px;
  color: $main-text-color;
}

body {
  position: relative; // для виджета загрузки
}

#root {
  width: 100%;
  min-height: 100%;
}

a {
  color: $link-color;
  text-decoration: none;
  &:hover {
    color: $color-malachite-02;
    cursor: pointer;
  }
}

h5 {
  font-size: 1em;
  margin-top: 10px;
  margin-bottom: 30px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #d0d7dd;
  margin: 1em 0;
  padding: 0;
}

button {
  flex-shrink: 0;
}

input::-ms-clear, input::-ms-reveal {
  display: none;
}
