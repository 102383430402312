.container {
  width: 90vw;
  height: 90vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 1vmin;
}