$main-bg-color: #ffffff;
$secondary-bg-color: #f2f4f7;
$border-color: #d0d7dd;
$link-color: #1358bf;
$error-color: #c11030;
$green-light: #86c239;

$outline-focus-color: #ffdd64;
$outline-focus-width: 2px;
$layout-max-width: 1400px;
$layout-padding-xs: 0 12px;
$layout-padding: 0 30px;
$layout-padding-extensive: 0 60px;

$black-color: #000;
$overlay-color: #1f1f22;
$overlay-opacity: 0.5;
$overlay-background-color: rgba($overlay-color, $overlay-opacity);

$main-transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);

$main-text-color: #1f1f22;
$secondary-text-color: #7d838a;
$menuWidth: 224px;
$tablePaddingHorizontal: 32px;
$tablePaddingHorizontalXS: 12px;

// Malachite.
$color-malachite-01: #005e7f; // Нажатое состояние кнопок, цвет ссылок.
$color-malachite-02: #107f8c; // Базовый цвет. Кнопки “Создать” / “Отправить” / “Подключить” и т.п.
$color-malachite-03: #21a19a; // Hover на внешних ссылках и на кнопках.
$color-malachite-04: #90d0cc; // Состояние Disabled.
$color-malachite-05: #e5fcf7; // Состояние виджета ВЭД, hover и active состояние фона ссылок меню.

// Sky.
$color-sky-01: #0f5498; // Состояние Active текстовых ссылок.
$color-sky-02: #1358bf; // Текстовые ссылки, бордер инфо-уведомлений, иконки.
$color-sky-03: #198cfe; // Hover на ссылках.
$color-sky-04: #93b7ed; // Состояние Disabled для ссылок.
$color-sky-05: #f2f8ff; // Фон инфо-алертов.

// Ruby.
$color-ruby-01: #a80e2a; // Нажатое состояние кнопки, бордер алерт-уведомлений.
$color-ruby-02: #c11030; // Критические алерты, ошибки, Алерт-кнопка, состояние Default.
$color-ruby-03: #db1237; // Hover на кнопках.
$color-ruby-04: #ffbdc5; // Состояние Disabled.
$color-ruby-05: #ffe5e8; // Заливка Alert.

// Pumpkin.
$color-pumpkin-01: #e58a00; // Нажатое состояние кнопок.
$color-pumpkin-02: #ff9900; // Кнопки “Call to action”, выбранные контролы, фон инфо-бэджей, иконки в некритичных алертах.
$color-pumpkin-03: #ffb13b; // Hover на кнопках “Call to action”.
$color-pumpkin-04: #ffd9a0; // Состояние Disabled, светлая заливка в контролах, фокус-обводка.
$color-pumpkin-05: #fff5e6; // Состояние виджета ВЭД, фон инфо-алертов.

// Sunny.
$color-sunny-01: #ffdd64; // Фокус-обводка.

// Graphite.
$color-graphite-01: #1f1f22; // Основной цвет текста на странице для всех заголовков и наборного текста на светлом фоне, активные пункты табов.
$color-graphite-02: #565b62; // Ссылки во втором уровне меню, темные элементы иконок цвета Asphalt 02.

// Asphalt.
$color-asphalt-01: #7d838a; // Активные навигационные табы, названия столбцов в таблицах, плейсхолдеры, текст описания, подписи.
$color-asphalt-02: #b2b8bf; // Иконоки, вспомогательные ссылки.
$color-asphalt-03: #d0d7dd; // Разделители, границы инпутов, границы блоков, иконки в таблицах.
$color-asphalt-04: #e4e8eb; // Фон второго уровня, разделители таблиц, сетка графиков, Hover навигационных табов.
$color-asphalt-05: #f2f4f7; // Фон в состоянии Disabled и правом сайдбаре на главной странице, фон для хедера страницы, фон заголовка таблицы, фон шапки лайтбокса, футер лайтбокса, фон трекера, фон аккордеона.

// Snowy.
$color-snowy: #ffffff;

@mixin hide-scroll-bar {
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scrollbar-height: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

// Должно быть сопоставление с js переменными из consts/app.ts
$breakpointXS: 0px;
$breakpointSM: 768px;
$breakpointMD: 1024px;
$breakpointLG: 1225px;
$breakpointXL: 1920px;

$sber-green: #21a038;
