@import '../../assets/styles/partials/variables';

.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: $layout-padding-xs;
}

.alertWrapper {
  margin-bottom: 20px;
}

.loginForm {
  position: relative;
  width: 100%;
  background-color: $main-bg-color;
  padding: 40px 20px;
  border: 1px solid $border-color;
  border-radius: 16px;
}

.forgotButton {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: $breakpointSM) {
  .loginForm {
    padding: 40px;
    max-width: 400px;
  }
}
