.container {
  display: flex;
  align-items: center;
}

.containerLeft {
  justify-content: flex-start;
}

.containerCenter {
  justify-content: center;
}

.containerRight {
  justify-content: flex-end;
}

.label {
  margin-right: 5px;
}

.containerUnderline {
  // эти стили применить сразу, не при наведении
  cursor: pointer;
  text-decoration: underline;
}
