// Fixing styles build errors for sbbol styles
.theme__default .dropdownList__zUILdA .dropdownListItem__BRgNAw .globalDropdownListItemContent {
  display: block;
  padding: 6px 8px;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.theme__default .dropdownList__zUILdA .dropdownListItem__BRgNAw .globalDropdownListItemContent.with-notification-no-hash:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f90
}

.theme__default .dropdownList__zUILdA .dropdownListItem__BRgNAw.active-no-hash > .globalDropdownListItemContent {
  background-color: #f2f4f7;
  cursor: pointer
}

.theme__default .dropdownList__zUILdA .dropdownListItem__BRgNAw.selected-no-hash > .globalDropdownListItemContent {
  background-color: #d0d7dd;
  cursor: default
}

.theme__default .caretdown-fill {
  fill: #1f1f22;
}

.row-wrapper {
  &--centered {
    div[class^=row__] {
      align-items: center;
    }
  }
}

.datePickerCalendarIcon__dKcPmQ {
  right: 8px !important;
}

.segmentedControlItem__0IkRxw {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.segmentedControlItemInner__N2UTww {
  text-overflow: clip !important;
}
