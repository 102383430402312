.input {
  input {
    height: 40px !important;
  }
}

.inputs {
  margin-bottom: 20px;
  >* {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
