@import '../../../assets/styles/partials/variables';

$borderWidth: 0;
$borderWidthSecondary: 1px;
$borderWidthFocus: 2px;
$paddingHorizontal: 24px;
$paddingHorizontalXl: 50px;
$paddingVerticalMd: 8px;
$paddingVerticalLg: 12px;
$paddingVerticalXl: 19px;

.button {
  font-family: SBSansUI,Arial,sans-serif;;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  text-transform: none;
  color: #fff !important;;
  cursor: pointer;
  display: inline-block;
  outline: none;
  border-style: solid;
  background-clip: padding-box;
  position: relative;
  margin: 0;
  border-radius: 1000px;
  text-align: center;
  border-width: $borderWidth;
  &:focus:not(:active) {
    border-color: $outline-focus-color !important;
    border-width: $borderWidthFocus;
  }
}

.buttonLoading {
  pointer-events: none;
  position: relative;
  path {
    fill: white;
  }
}

.buttonFullWidth {
  width: 100%;
}

.buttonMd {
  padding: ($paddingVerticalMd - $borderWidth) ($paddingHorizontal - $borderWidth);
  &:focus:not(:active) {
    padding: ($paddingVerticalMd - $borderWidthFocus) ($paddingHorizontal - $borderWidthFocus);
  }
}

.buttonLg {
  padding: ($paddingVerticalLg - $borderWidth) ($paddingHorizontal - $borderWidth);
  &:focus:not(:active) {
    padding: ($paddingVerticalLg - $borderWidthFocus) ($paddingHorizontal - $borderWidthFocus);
  }
}

.buttonXl {
  font-size: 16px;
  padding: ($paddingVerticalXl - $borderWidth) ($paddingHorizontalXl - $borderWidth);
  &:focus:not(:active) {
    padding: ($paddingVerticalXl - $borderWidthFocus) ($paddingHorizontalXl - $borderWidthFocus);
  }
}

.buttonGeneral {
  background-color: $color-malachite-02;
  &:hover {
    background-color: $color-malachite-03 !important;
  }
  &:active {
    background-color: $color-malachite-01 !important;;
  }
}

.buttonDisabled {
  cursor: default;
}

.buttonGeneralDisabled {
  background-color: $color-malachite-04 !important;;
}

.buttonDanger {
  background-color: $error-color;
  &:hover {
    background-color: $color-ruby-03 !important;;
  }
  &:active {
    background-color: $color-ruby-01 !important;;
  }
}

.buttonDangerDisabled {
  background-color: $color-ruby-04;
}

.buttonSecondary {
  font-weight: 400;
  color: $main-text-color !important;;
  background-color: #fff;
  border-color: $border-color;
  border-width: $borderWidthSecondary;
  &:hover {
    border-color: $main-text-color;
  }
  &:active {
    background-clip: border-box;
    background-color: $border-color;
    border-color: transparent;
  }
}

.buttonSecondaryLoading {
  path {
    fill: $color-malachite-02;
  }
}

.buttonSecondaryMd {
  padding: ($paddingVerticalMd - $borderWidthSecondary) ($paddingHorizontal - $borderWidthSecondary);
  &:focus:not(:active) {
    padding: ($paddingVerticalMd - $borderWidthFocus) ($paddingHorizontal - $borderWidthFocus);
  }
}

.buttonSecondaryLg {
  padding: ($paddingVerticalLg - $borderWidthSecondary) ($paddingHorizontal - $borderWidthSecondary);
  &:focus:not(:active) {
    padding: ($paddingVerticalLg - $borderWidthFocus) ($paddingHorizontal - $borderWidthFocus);
  }
}

.buttonSecondaryXl {
  padding: ($paddingVerticalXl - $borderWidthSecondary) ($paddingHorizontalXl - $borderWidthSecondary);
  &:focus:not(:active) {
    padding: ($paddingVerticalXl - $borderWidthFocus) ($paddingHorizontalXl - $borderWidthFocus);
  }
}

.buttonSecondaryDisabled {
  background-color: #fff;
  border-color: $border-color;
  color: $border-color !important;;
}

.buttonDisabled {
  pointer-events: none;
}

.hidden {
  visibility: hidden;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
